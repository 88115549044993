import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Critical Mass
Critical Mass is a bicycling event typically held on the last Friday of every month in over 300 cities around the world. The ride was originally founded in 1992 in San Francisco with the idea of drawing attention to how unfriendly the city was to cyclists. In fact, the purpose of Critical Mass is not usually formalized beyond the direct action of meeting at a set location and time and traveling as a group through city or town streets on bikes. Although for some bigger scale events like the one in Budapest, Hungary there is an activist group formed around it, organizing the rides and communicating the desires and problems of the cyclists to the city council. (`}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Critical_Mass"
      }}>{`http://en.wikipedia.org/wiki/Critical_Mass`}</a>{`, visited 09/08/10)`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/RaschinonBike2.jpg",
        "alt": "Raschin_bike2"
      }}></img></p>
    <p>{`What if we could see all bike traces in the streets?
How the city would look like with all visible bike traces?`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=p3kqt4EyEmM&feature=emb_logo"
      }}>{`critical_mass`}</a></p>
    <p>{`To implement this idea, I placed a bottle of washable paint under my bikes seddle and start biking around Potrero Hill and Hayes Valley streets. The paint left the trace of bike path on the streets.A camera was attached to the back of the bike to capture the trace and lines of my bike.Using applications, such as instamapper, enables bikers to visualize their traces. Each path has a specific width. This means the more bikers use a path the width of their traces increases. These lines creat a pattern in the city that can be used for development of facilities for bikers.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/toblog2.jpg",
        "alt": "city_viz"
      }}></img></p>
    <p>{`Prototype Structure:`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/bikewide2.jpg",
        "alt": "bike_prototype"
      }}></img></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      